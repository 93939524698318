import { Elements, ElementsConsumer } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React from "react"
import Header from "../components/Header"
import PaymentForm from "./PaymentForm"
import { useTranslation } from "react-i18next";
const PUBLIC_KEY = "pk_test_51Ll9yWSDFNnmZyfV228dqFJEMinsbKWEyPVrcGn6mBmLwvi6EMCxUS2e3STTEMxMfore0nxj8kEjohIsB1E3ATNx00fc9WBklk"

const stripeTestPromise = loadStripe(PUBLIC_KEY)
const clientSecret= 'sk_test_51Ll9yWSDFNnmZyfVg0r9CnKm49OdjzaS70KEQ5uIOfxFdlQAGcd5EMzfH9qhZkJvja1qjcWUO9lxvMi6ycuxDeGL00iFC9sTW2'


export default function Checkout () {
	const { clientSecret } = fetch('/secret', {method:"POST"}).then(r=> r.json())
	// console.log('clientSecret',clientSecret)
	const options = {
		// passing the client secret obtained from the server
		clientSecret: clientSecret,
	  };
 
const {t} = useTranslation()
	return (
		<>
		<div className='MainWhole-wrapper-container'> 
		<Header/>
		<div className="Checkout-StripeMain-wrapper">
			<h1 >
				{t('checkout_page_heading')}
			</h1>
			<h3> 
				{t('checkout_page_enter-details')}
				</h3> 
             <Elements stripe={stripeTestPromise} >		 
			   <PaymentForm />
			 </Elements>
		</div>	
    	</div>
		</>
	)
}
